// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".viewTagsModal-module___modal__title___1PSnS {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  margin-left: 7px;\n  margin-top: 4px;\n}\n\n.viewTagsModal-module___title__container___AbJFL {\n  display: flex;\n}\n\n.viewTagsModal-module___title__text___dfD8n {\n  margin-right: 5px;\n}\n\n.viewTagsModal-module___value__tag__container___DX-kb {\n  margin-top: 30px;\n  margin-bottom: 30px;\n}\n\n.viewTagsModal-module___value__tag___U4vFG > * > * {\n  margin: 4px;\n}\n\n.viewTagsModal-module___value__tag___U4vFG > * {\n  display: inline;\n}\n", "",{"version":3,"sources":["webpack://./src/js/components/viewTagsModal/viewTagsModal.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,eAAe;AACjB","sourcesContent":["._modal__title {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  margin-left: 7px;\n  margin-top: 4px;\n}\n\n._title__container {\n  display: flex;\n}\n\n._title__text {\n  margin-right: 5px;\n}\n\n._value__tag__container {\n  margin-top: 30px;\n  margin-bottom: 30px;\n}\n\n._value__tag > * > * {\n  margin: 4px;\n}\n\n._value__tag > * {\n  display: inline;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_modal__title": "viewTagsModal-module___modal__title___1PSnS",
	"_title__container": "viewTagsModal-module___title__container___AbJFL",
	"_title__text": "viewTagsModal-module___title__text___dfD8n",
	"_value__tag__container": "viewTagsModal-module___value__tag__container___DX-kb",
	"_value__tag": "viewTagsModal-module___value__tag___U4vFG"
};
export default ___CSS_LOADER_EXPORT___;
