// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".aliasName-module__container___cqNtG {\n  display: flex;\n}\n\n.aliasName-module__container___cqNtG > * {\n  margin: 0 8px;\n}\n", "",{"version":3,"sources":["webpack://./src/js/components/connectionCard/aliasName/aliasName.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;;AAEA;EACE,aAAa;AACf","sourcesContent":[".container {\n  display: flex;\n}\n\n.container > * {\n  margin: 0 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "aliasName-module__container___cqNtG"
};
export default ___CSS_LOADER_EXPORT___;
